body {
    margin: 0;
    padding: 0;
    font-family:"Poppins-Regular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }
  button {
    cursor: pointer;
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"),
     url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
 
  p.lorem-text {
    text-align: justify;
  }
  
  .modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.8);
  }
  label{
   padding-top: 10px;
   font-size: larger;
  }
  .modal-area {
    position: absolute;
    
   width:100%;
    height:100%;
    padding: 20px;
    background: linear-gradient(242.57deg, #7CFFFF -0.29%, #2541CC 88.33%);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    border-radius: 8px;
    -webkit-overflow-scrolling: touch;
  }

  
  .modal-area::-webkit-scrollbar {
    width: 0.4em;
    
  }
  .modal-area::-webkit-scrollbar-track{
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  
  }
  .modal-area::-webkit-scrollbar-thumb{
    background-color: rgb(10,10,10,.5);
    outline: '1px solid slategrey';
    border-radius: 8px;
  }
  .modal-area-setUp{
 
    width: 70%;
    height: 90%;
    padding: 20px;
    background: linear-gradient(242.57deg, #7CFFFF -0.29%, #2541CC 88.33%);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 8px;
    -webkit-overflow-scrolling: touch;
  }
  .modal-area-confirm {
    
  
    width: 60%;
    margin: auto;
    padding: 20px;
    background: linear-gradient(242.57deg, #7CFFFF -0.29%, #2541CC 88.33%);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 8px;
    -webkit-overflow-scrolling: touch;
  }
  .formContainer{
   width:30%;
   justify-content: space-between;
   margin: auto;
    display: flex;
    flex-direction: column;
  }
  .formContainerConfirm{
    width:30%;

    margin: auto;
     display: flex;

   }
  @media screen and (min-width: 500px) {
    /* Center the Modal! */
    .modal-area {
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      max-width: 1242px;
      max-height: 90%;
    }
  }
  
  ._modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    line-height: 1;
    background: transparent;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
  }
  
  ._modal-close-icon {
    width: 25px;
    height: 25px;
    fill: transparent;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 2;
  }
  
  .modal-body {
    display:flex;
    justify-content: space-around;
    flex-direction: row;
  }
  .modal-body-setUp {
    display: flex;
    flex-direction: row;
    
  }
  .modal-body-confirm {
    display:flex;
    justify-content: space-around;
    flex-direction: row;
  }
  ._hide-visual {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  
  .scroll-lock {
    overflow: hidden;
    margin-right: 17px;
  }
  .inputs-ProPic{
    box-sizing: border-box;

width: 270px;
height: 200px;

border: 1px solid #FFFFFF;
border-radius: 8px;

/* Inside auto layout */
background-color: transparent;

order: 1;
padding:2px;
flex-grow: 0;
  }
  .inputs{
    box-sizing: border-box;
    outline-width:0px;
width: 100%;
padding-left:5px;
height: 44px;
margin: auto;
border-bottom: 1px solid #00D4C5;
border-radius: 8px;

/* Inside auto layout */
background-color: transparent;
flex: none;
order: 1;
padding:2px;
flex-grow: 0;
  }
  label{
    font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */


color: #FFFFFF;
  }

  .subMitButton{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 100px;
gap: 10px;
background: #40E2DE;
border-radius: 8px;
margin: auto;
margin-top: 20px;
  }

  .terms{
    width: 342px;
height: 64px;
color:white;
text-align: center;
margin-top: 20px;
font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 500;
font-size: small;
line-height: 18px;
  }
  .walletButton{
  display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
padding: 5px 0px;
width: 50%;
flex-wrap: nowrap;
margin: auto;
margin-top: 20px;
font-weight: 600;
background: #FFDE6A;
box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
border-radius: 12px;

  }

  .inputsBio{
    box-sizing: border-box;

width: 70%;
height: 100px;

border: 1px solid #FFFFFF;
border-radius: 8px;

/* Inside auto layout */
background-color: transparent;
flex: none;
order: 1;
padding: 12px;
flex-grow: 0;
  }

  .mobile-modal-area {
 
    overflow-y: scroll;
    background: linear-gradient(242.57deg, #7CFFFF -0.29%, #2541CC 88.33%);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  
    border-radius: 8px;
    -webkit-overflow-scrolling: touch;
  }
  .mobile-modal-area-setUp{
 
    overflow-y: scroll;
    background: linear-gradient(242.57deg, #7CFFFF -0.29%, #2541CC 88.33%);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  
    border-radius: 8px;
    -webkit-overflow-scrolling: touch;
  }
  .mobile-modal-area-confirm {
    
  
    background: linear-gradient(242.57deg, #7CFFFF -0.29%, #2541CC 88.33%);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  
    border-radius: 8px;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-modal-body {
    display:flex;
    justify-content: space-around;
    flex-direction: row;
    height:fit-content
  }
  .mobile-modal-body-setUp {
    display:flex;
    justify-content: space-around;
    flex-direction: row;
    height:fit-content
    
  }
  .mobile-modal-body-confirm {
    display:flex;
    justify-content: space-around;
    flex-direction: row;
    height:fit-content
  }
  ._mobile-modal-close {
    width: 64px;
    height:64px;
    
    line-height: 1;
    float:right;
    background: transparent;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
  }
  
  ._mobile-modal-close-icon {
    width: 24px;
    height:24px;
    fill: transparent;
    stroke: #2869E7;
    stroke-linecap: round;
    stroke-width: 2;
  }
  .mobileHeadliner{
    width: 366px;
height: 24px;

font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 600;
font-size: 23.04px;
line-height: 24px;
/* identical to box height, or 104% */

text-align: center;
text-transform: capitalize;

color: #0E16D9;

  }

 .mobileHeader2{ 
  width: 366px;
height: 20px;

font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
/* identical to box height, or 125% */

text-align: center;

color: #FFFFFF;
}

.mobileFormgroup {
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 8px;
width:330px;
height:75px;
margin-left: 40px;
margin-right: 40px;
margin-top: 28px;
margin-bottom:24px;
}
.mobileFormgroupInput{
  display: flex;
  flex-direction: row;
  width: 100%;
 justify-content: space-between;
  background-color: transparent;
  
width: 100%;
height: 44px;

border: 1px solid #FFFFFF;
border-radius: 8px;

/* Inside auto layout */
background-color: transparent;
flex: none;
order: 1;
padding:2px;
flex-grow: 0;
}
.mobileTerms{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px;

margin-left: 40px;
margin-right: 40px;
margin-top: 28px;
margin-bottom:16px;

}

.mobileTerms p{
  width: 330px;
height: 64px;

font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 500;
font-size: 13.33px;
line-height: 16px;
/* or 120% */


color: #FFFFFF;
}

.mobileSubmit{

margin-left: 40px;
margin-right: 40px;
margin-top: 16px;
margin-bottom:16px;

}
.mobileConfirmSubmit{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 100px;
  gap: 10px;
  flex-wrap: nowrap;
  background: #40E2DE;
  border-radius: 8px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 16px;
  margin-bottom:16px;
  
  }

  .mobileConfirmSubmit p{
    width: 77px;
    height: 20px;
    flex-wrap: nowrap;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */
    flex: none;
order: 1;
flex-grow: 0;
    
    color: #FFFFFF;
  }
  .MobileinputsBio{
    box-sizing: border-box;

width: 100%;
height: 100px;

border: 1px solid #FFFFFF;
border-radius: 8px;

/* Inside auto layout */
background-color: transparent;
flex: none;
order: 1;
padding: 12px;
flex-grow: 0;
  }
#metaMaskColor{
  background: #FFDE6A;
}
.webBannerSetUp{
  position: absolute;
 height:340px;
  left: 0px;
  right: 0px;
  
  background:#D7E1ED ;
}
.webBannerButton{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top:105px;
padding:10px;
  margin:auto;
 
 
}
.webBannerUploaded{
  position: absolute;
  width:430px;
  height:340px ;
  left: 0px;
  right: 0px;
  
  background:transparent ;
}

.webuserAvatarHolder{
 
  position: absolute;
  width: 200px;
  height: 200px;
  margin:auto;
  top: 304px;
  margin:auto;
  background: #2541CC;
  margin-left:40%;
  margin-right:40%;
  border: 2px solid #FFFFFF;
  border-radius: 100%;

  }
  .webProfileButton{
    display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  
 
  margin:auto;
  }
  .webuserAvatarUploaded{
    position: absolute;
    width: 200px;
    max-width: 200px;
    display:block;
    max-height: 200px;
    height: 200px;
    margin:auto;
    top: 280px;
    margin-bottom:5%;
  
    margin-left:40%;
    margin-right:40%;
    border: 2px solid #FFFFFF;
    border-radius: 100%;
   
   overflow: hidden;
    }
 

















.mobileBannerSetUp{
  position: absolute;
  height: 264px;
  left: 0px;
  right: 0px;
  top: 80px;
  background:#D7E1ED ;
}















.mobileBannerUploaded{
  position: absolute;
  width:430px;
  height: 264px;
  left: 0px;
  right: 0px;
  top: 80px;
  background:transparent ;
}





.mobileBannerButton{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:105px;
  gap: 10px;
 margin:'auto';
  position: absolute;

 
}
.MobileuserAvatarHolder{
 
position: absolute;
width: 160px;
height: 160px;
left: 135px;
top: 304px;

background: #2541CC;
border: 2px solid #FFFFFF;
border-radius: 100%;
}
.MobileuserAvatarUploaded{
 
  position: absolute;
  width: 160px;
  height: 160px;
  left: 135px;
  top: 304px;
  
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 100%;
  }
.mobileProfileButton{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;

position: absolute;
left: 44.42%;
right: 44.42%;
top: 38.63%;
bottom: 56.22%;
}

.userDetailsMobile{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding: 0px 8px;
gap: 10px;

position: absolute;
height: 24px;
left: 6.98%;
right: 6.98%;
top: 488px;
}

#userNameMobile{
  width: 354px;
height: 24px;

font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 24px;
text-align: center;

color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
}


.userprefnameDetailsMobile{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding: 0px 8px;
gap: 10px;

position: absolute;
height: 24px;
left: 30px;
right: 30px;
top: 528px;

}

#userprefNameMobile{
  width: 354px;
  height: 24px;
  
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
  
  text-align: center;
  
  color: #2DCCD3;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 1;
}

.userBioDetailsMobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 8px;
  gap: 10px;
  
  position: absolute;
  height: 24px;
  left: 30px;
  right: 30px;
  top: 584px;
  
}

#userbioMobile{
  width: 354px;
  height: 24px;
  
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
  
  text-align: center;
  
  color: #000000;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 1;
}

.userMobileBioInput{
  box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 16px;

position: absolute;
width: 370px;
height: 60px;
left: 30px;
top: 626px;

background: #CDFDFD;
border: 1px solid #D7E1ED;
border-radius: 8px;
}

.userMobileBioInputBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 16px;
  font-family: 'Poppins-Regular';
  width: 350px;
  height: 44px;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
}
.userBioSubmitmobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  
  position: absolute;
  width: 154px;
  height: 56px;
  left: 138px;
  top: 718px;
  
  background: #209290;
  border-radius: 8px;
}
.userBioSubmitmobile p{
  width: 74px;
height: 24px;
font-family: 'Poppins-Regular';

font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.userwebBioInput{
  box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 16px;

position: absolute;
width: 370px;
height: 60px;
margin-left:45%;
margin-right:45%;
top: 640px;

background: #CDFDFD;
border: 1px solid #D7E1ED;
border-radius: 8px;
}

.userwebBioInputBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 16px;
  font-family: 'Poppins-Regular';
  width: 350px;
  height: 100px;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
}
.userBioSubmitweb{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  
  position: absolute;
  width: 154px;
  height: 56px;
  margin-left:45%;
  margin-right:45%;
  margin-top: 700px;
  
  background: #209290;
  border-radius: 8px;
}
.userBioSubmitweb p{
  width: 74px;
height: 24px;
font-family: 'Poppins-Regular';

font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}