body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
   url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div::-webkit-scrollbar {
  width: 0.4em;
  
}
div::-webkit-scrollbar-track{
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);

}
div::-webkit-scrollbar-thumb{
  background-color: rgb(10,10,10,.5);
  outline: '1px solid green';
  border-radius: 8px;
}