/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2022 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@font-face {
  font-family: "Poppins-Regular";
  src: local(Poppins-Regular),
  
    url(./Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: local(Poppins-Medium),
    
    url(./Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local(Poppins-SemiBold),

    url(./Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local(Poppins-Bold),

    url(./Poppins-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: local(Poppins-ExtraBold),
  
    url(./Poppins-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

.Heading-DisplayH1ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 88px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
}

.Heading-DisplayH1Bold {
  font-family: "Poppins-Bold";
  font-size: 88px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
}

.Heading-DisplayH1Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 88px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
}

.Heading-DisplayH2ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 72px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

.Heading-DisplayH2Bold {
  font-family: "Poppins";
  font-size: 72px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

.Heading-DisplayH2Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 72px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

.HeadingH1ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 57.3px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
}

.HeadingH1Bold {
  font-family: "Poppins";
  font-size: 57.3px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
}

.HeadingH1Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 57.3px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
}

.Heading-DisplayH3ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 56px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

.Heading-DisplayH3Bold {
  font-family: "Poppins";
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

.Heading-DisplayH3Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 56px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

.HeadingH2ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 47.8px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
}

.HeadingH2Bold {
  font-family: "Poppins";
  font-size: 47.8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
}

.HeadingH2Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 47.8px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
}

.HeadingH3ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 39.8px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
}

.HeadingH3Bold {
  font-family: "Poppins";
  font-size: 39.8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
}

.HeadingH3Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 39.8px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
}

.HeadingH4ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 33.2px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
}

.HeadingH4Bold {
  font-family: "Poppins-Bold";
  font-size: 33.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
}

.HeadingH4Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 33.2px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
}

.HeadingH5ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 27.6px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
}

.HeadingH5Bold {
  font-family: "Poppins";
  font-size: 27.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
}

.HeadingH5Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 27.6px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  color:'#000000';
}

.HeadingH6ExtraBold {
  font-family: "Poppins-ExtraBold";
  font-size: 23px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
}

.HeadingH6Bold {
  font-family: "Poppins";
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
}

.HeadingH6Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
}
::-webkit-scrollbar {
  width: 0.2em;
  
}
::-webkit-scrollbar-track{
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1);

}
::-webkit-scrollbar-thumb{
  background-color: rgb(206, 232, 255)  ;
  outline: '1px solid slategrey';
  border-radius: 8px;
}
.ParagraphP1Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 19.2px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.ParagraphP1Medium {
  font-family: "Poppins-Medium";
  font-size: 19.2px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.ParagraphP1Regular {
  font-family: "Poppins-Regular";
  font-size: 19.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.ParagraphP2Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.ParagraphP2Medium {
  font-family: "Poppins-Medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.ParagraphP2Regular {
  font-family: "Poppins-Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.ParagraphP3Semibold {
  font-family: "Poppins-SemiBold";
  font-size: 13.3px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.ParagraphP3Medium {
  font-family: "Poppins-Medium";
  font-size: 13.3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.ParagraphP3Regular {
  font-family: "Poppins-Regular";
  font-size: 13.3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}