.loading-dot {
    font-size: 100px;
    margin: 'auto';
    display:block;
    animation: ease-in-out infinite alternate;
    animation-name: run;
    animation-duration: 1.2s;
  }
  
  @keyframes run {
    0% {
      left: -90px;
      color: #eee;
    }
    50% {
      color: #666;
    }
    100% {
      left: 90px;
      color: #eee;
    }
  }