*{
    margin: 0;
    padding: 0;
    font-family: "Poppins-Regular";
    box-sizing: border-box;
}
@font-face {
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"),
     url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    }
    @font-face {
        font-family: "Lato-Bold";
        src: local("Lato-Bold"),
         url("../assets/fonts/Lato-Bold.ttf") format("truetype"),
         url("../assets/fonts/Lato-Medium.ttf") format("truetype"),
         url("../assets/fonts/Lato-Light.ttf") format("truetype"),
         url("../assets/fonts/Lato-Regular.ttf") format("truetype");
        font-weight: normal;
        }
.main{
   height: fit-content;
   padding: 10px;
   width: 100%;
   flex-direction: row;
   
   display: inline-block;

}
#logo{
    margin-top:auto;
    margin-bottom:auto;
}
#logoContainter{
  display: inline-block;
  padding: 10px;
    max-height: 41px;
    justify-content: center;
    margin-top:auto;
    margin-bottom:auto;
}
#links{
    display: flex;
    flex-direction: row;
    float: right;

    justify-content: space-evenly;
   
    width: 35%;
    padding: 10px;
    
    margin-left: 15px;
}
#text{
    
font-style: normal;

font-size: 16px;
background-color: transparent;
border: none;
display: flex;
align-items: center;
font-weight: bold;
color: #000000;
}
#icon{
   margin-right:10px;
 
   vertical-align: middle;
}
#sign{
    background-color: #2541CC;
    color: white;
   font-size: 16px;
   font-weight: 500;
    height: 40px;
    width: 120px;
    padding:5px;
  font-family: "Poppins-Regular";
    justify-content: space-between;
    text-align: center;
    border: transparent 0px solid;
    border-radius: 8px;
    margin-right: 15px;
}
#sign2{
    background-color: transparent;
    color: white;
   font-size: 16px;
   font-weight: 500;
    height: 40px;
    width: 120px;
    padding:5px;
  font-family: "Poppins-Regular";
    justify-content: space-between;
    text-align: center;
    border: transparent 0px solid;
    border-radius: 8px;
    margin-right: 15px;
}
#logOut{
    background-color: transparent;
    font-family: "Poppins-Regular";
    color:black;
    padding:5px;
    text-decoration:'none';
    font-size: 16px;
    font-weight: bold;
     height: 40px;
     width: 120px;
    justify-content: space-between;
    text-align: center;
    border: transparent 0px solid;
    border-radius: 8px;
 
}
#cards{
    flex-wrap:wrap;
    width:min-content;
    justify-content:center;
    
    background-color: transparent;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25); 
    border-radius: 12px;
    max-height: 480px;
    padding-bottom:10px;
}
#cardBody{

    height: 20%;
    background: '#FFFFFF';
    flex-wrap:wrap;
    flex-direction: row;
    display: inline-flex;
}
#dropDownProfile{

}
#username{
    padding-top: auto;
    text-align: left;
   
}